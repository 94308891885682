@import 'variables'

.multicolumn-navbar
    display: flex
    justify-content: space-between
    align-items: flex-start

    .multicolumn-navbar-column
        display: flex
        flex-direction: column

        a
            color: $regent-gray
            font-family: $font-family-montserrat
            font-size: $font-size-m
            font-style: normal
            font-weight: 400
            text-decoration: none
            line-height: 3rem

            &:first-child
                font-weight: bolder
    
    .buttonblock
        font-size: $font-size-m
        color: $regent-gray
        a
            color: $regent-gray
        .component-contact-section-qrcode
            width: rem(240px)
            height: rem(80px)
            border-radius: 1rem
            padding: 0.25rem
            .qrcode, svg
                height: 4rem
                width: 4rem

            .component-contact-section-qrcode-title
                margin-top: 4px
                text-transform: none !important
                a
                    color: #536ae2
            