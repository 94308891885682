@import 'variables'
@import 'rem'


.component-button
  cursor: pointer
  border-radius: 10px
  align-items: center
  justify-content: center
  height: 50px
  background-color: $white
  display: flex
  text-align: center
  width: rem(140px)
  margin: 0 0.3rem
  &>span
    width: 100%
  &.blue
    background-color: $blueberry
    box-shadow: 0px 10px 25px #6980fe80
  &.gray
    background-color: #ffffff0d
  &.none
    background-color: unset

.component-button-nav
  height: inherit
  display: flex
  width: 100%
  justify-content: center
  flex-direction: column
  align-items: center
  div, svg, img
    width: rem(42px)
    height: rem(42px)

.component-button-with-icon
  height: inherit
  display: grid
  width: 100%
  grid-template-columns: 1fr 2fr

.component-button-title
  text-align: center
  text-transform: capitalize
  margin: auto 0

.button-7
  align-items: center
  background-color: $white
  border-radius: 10px
  display: flex
  height: 50px
  overflow: hidden
  padding: 0 47px
  width: rem(140px)


.button-7.button-23,
.button-7.button-49
  min-width: 140px
  overflow: unset
  width: unset

