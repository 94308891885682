@import 'variables'
@import 'mixins'
@import 'rem'

.welcome-block
    background-size: 700px !important
    background-repeat: no-repeat
    background-position-x: center
    background-position-y: unset
    
.container-macbook-image
    .background
        background-position-y: top
        z-index: 1

    .content
        align-items: center
        justify-content: center

        .rectangle-ball
            width: 504px
            height: 232px
            background: #6980FE
            filter: blur(125px)
            z-index: -1

.download-mobile-card
    position: relative
    height: calc(15rem + rem(320px))
    @include desktop
        width: rem(568px)
    .linksection
        position: absolute
        bottom: 2rem
        right: 2rem
        z-index: +1
    .content
        padding-bottom: 0rem
        h3
            color: $black !important
            font-weight: 600
            width: 100%
            flex-direction: column
        .text
            display: flex
            flex-direction: column
        .title, .subtitle, .text
            text-align: center !important
            width: 100%
        .image-component
            padding-bottom: 0rem
            height: 25.5rem
            position: absolute
            background-position-y: rem(150px)
            bottom: 0
            overflow: hidden
            margin-left: auto
            margin-right: auto
            left: 0
            right: 0

.mobile-store-icon
    width: 173px
    height: 56px !important

.mobile-store-device
    height: 25.5rem

.download-platforms-cards
    width: 100%
    .content
        display: flex
        align-items: center
        .component-button
            color: white
            margin-top: 30px
            .component-button-with-icon
                display: flex
                justify-content: space-evenly
                align-items: center
                .component-button-icon
                    width: 12px
                    height: 16px
        .text
            justify-content: center

.downloads
    overflow: hidden